import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

export default function NotFound() {
  return (
    <Layout>
      <div className="p-12">
        <h1 className="text-4xl font-semibold mb-8">Page not found</h1>
        <p className="mb-8 font-sans text-lg text-gray-700 dark:text-gray-500">
          Oops! The page you were looking for could not be found.
        </p>
        <Link
          className="font-semibold text-purple-800 dark:text-purple-600 underline"
          to="/"
        >
          Go Back
        </Link>
      </div>
    </Layout>
  )
}
